<template>
  <div class="columns">
    <div class="column is-three-fifths is-offset-one-fifth">
      <h1 class="title">Setting Kartu Anggota</h1>
      <div class="columns is-multiline pt-2">
        <div
          class="column is-2 has-text-centered pl-2"
          v-for="item in images"
          :key="item._id"
        >
          <b-image
            :src="item.files"
            alt="A random image"
            class="iis-128x128 mb-2"
          ></b-image>
          <b-button @click="deleteImage(item._id)" expanded>Delete</b-button>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <section>
          <b-field>
            <b-upload v-model="dropFiles" multiple drag-drop expanded>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>

          <b-button @click="handleSubmit" expanded>Save</b-button>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dropFiles: null,
      images: []
    };
  },
  mounted() {
    axios.get("http://localhost:3000/images").then(res => {
      this.images = res.data;
    });
    this.getNomor();
  },
  methods: {
    handleSubmit() {
      const formData = new FormData();
      for (const i of Object.keys(this.dropFiles)) {
        formData.append("files", this.dropFiles[i]);
      }
      axios
        .post("http://localhost:3000/file-upload", formData, {})
        .then(res => {
          console.log(res);
          this.dropFiles = null;
          this.$buefy.snackbar.open({
            message: res.data.message,
            type: "is-success",
            position: "is-top",
            actionText: "OK",
            indefinite: true,
            onAction: () => {}
          });
        });
    },
    deleteImage(val) {
      axios.delete("http://localhost:3000/images/" + val).then(res => {
        this.$buefy.toast.open({
          message: res.data.message,
          type: "is-success"
        });
        axios.get("http://localhost:3000/images").then(res => {
          this.images = res.data;
        });
      });
    }
  }
};
</script>
